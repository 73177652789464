import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CategoryTitle from "../components/index/categorytitle"
import { Link } from "gatsby"
import AsideItem from "../components/aside/item"

const Kurumsal = () => (
  <Layout>
    <SEO title="Корпоративный" />
    <div id="kurumsal" className="inlinePage footerFixed center-h">
      <div className="container-fluid w80 ma pt5 pb55 col-xs-pt15">
        <CategoryTitle title="SERENAY" subTitle="Корпоративный" description="Корпоративный" isDark={false} position="inline" />
        <div className="row">
        	<div className="w20 left pt15 mt15 col-sm-w100 pr45 col-sm-pr0">
        		<aside className="w100 left pos-r">
        			<ul className="w100 left fs14 fw6" style={{letterSpacing: "0.5px"}}>
			        	<AsideItem to="/корпоративный" title="Наша история" icon="icon-serenay" />
			        	<AsideItem to="/идентификация" title="Пропуск" icon="icon-kariyer1" />
			        	<AsideItem to="/безопасность" title="Гизлилик" icon="icon-shield2" />
			        </ul>
        		</aside>
        	</div>
        	<div className="w80 col-sm-w100 left bl1 bc1 col-sm-bl0 col-sm-pl0 pt15 mt15 pl45">
	        	<article className="w100 left fs18">
	        		<p className="colored3">
	        		<b>Serenay Software Ltd. Şti.</b> С <b>2006</b> года мы предоставляем корпоративный дизайн веб-страниц, решения для разработки программного обеспечения и консультационные услуги нашим уважаемым клиентам. <b> Турция четыре угла услуг, которые мы предоставляем </ b> Мы гордимся тем, что предоставляем нашим клиентам ценные решения. У вас есть проект? Взгляните на наши процессы проектирования, просмотрите наши рекомендации и истории успеха. Присылайте нам детали вашего проекта, используя наши каналы обслуживания клиентов и поддержки. Возможно, решение, которое вы ищете, находится здесь.
	        		</p>
	        		<p>
	        			<b className="w100 left lh38"><i className="icon icon-work mr10 left p10 b-circle colored3-bg white"></i>Project Processes</b> 
	        			<i className="fs20 fw5 colored3 pl45 col-xs-pl0 left">Для того чтобы максимально быстро превратить ваши потребности в продукт в процессе планирования проекта, Мы используем гибкие методы применения. Основные вопросы, которые мы берем за основу на этапе проектирования проекта, заключаются в следующем;</i>
	        		</p>
	        		<div>
	        			<ul className="w100 left ml25 pl20 col-xs-ml0 pt25 pb25 mt15 mb15 b-ra5 bs3" style={{backgroundColor:"#f7f7f7"}}>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Определение вами ваших потребностей и уведомление нас о ваших запросах</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Выездная проверка ваших потребностей нашей командой экспертов-системных аналитиков</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Определение дорожной карты в соответствии с неотложными ситуациями</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Предоставление инфраструктурных исследований для обеспечения того, чтобы приложение находилось в улучшаемом масштабе.</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Процесс разработки приложений и подготовки тестов</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Тестирование в производственной среде</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Перевод заявки в готовое состояние</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Предоставление вспомогательных услуг</li>

	        			</ul>
	        		</div>
	        		<p>
	        			<Link className="colored2 p15 mt15 wi left b-ra5 fw6" to="/solutions">Просмотрите Наши Решения <i className="icon icon-right5 fs8"></i></Link>
	        		</p>
	        	</article>
	        </div>
        </div>        
      </div>
    </div>
  </Layout>
)

export default Kurumsal
